
import { mapState } from 'vuex'
import hasPageMeta from '@/mixins/hasPageMeta'
import getCategoryUrl from '@/components/pages/articles/mixins/getCategoryUrl'
import capitalize from '~/mixins/capitalize'

export default {
    name: 'Home',

    mixins: [hasPageMeta, capitalize, getCategoryUrl],

    middleware: 'homepage',

    fetch() {
        this.$store.dispatch('jsonloader/fetchJson', 'home.json')
    },

    computed: {
        ...mapState({
            contentTypes: (state) => state.data.contentTypes,
            taxonomies: (state) => state.data.taxonomies,
            content: (state) => state.jsonloader.data['home.json'] || null,
            globalTexts: (state) => state.jsonloader.data['global.json'] || null,
        }),

        filteredPlanningContentTypes() {
            const planning = this.contentTypes.find((el) => el.slug === 'planning')
            if (planning && planning.children) return planning.children
            return []
        },

        contentMeta() {
            return this.content?.meta || {}
        },

        blocks() {
            return this.content?.blocks || {}
        },

        categories() {
            return (this.contentTypes.find((el) => el.slug === 'planning')?.children || []).filter((el) => !el.is_hidden)
        },

        styles() {
            return this.taxonomies?.styles || []
        },

        selectedStyle: {
            get() {
                return this.$route.query.style || undefined
            },

            set(val) {
                this.$router.push({ query: { ...this.$route.query, style: val } })
            },
        },

        realWeddingButtonLabel() {
            return this.selectedStyle ? `Browse ${this.capitalize(this.selectedStyle)} Weddings` : this.globalTexts.home['real-weddings'].button_text
        },

        weddingExtraQuery() {
            return {
                taxonomy_styles: this.selectedStyle,
            }
        },

        siteTitle() {
            return this.contentMeta.title || 'Loverly - Wedding Planning Ideas, Inspiration & Wedding Checklist'
        },

        siteDescription() {
            return (
                this.contentMeta.description ||
                'Loverly’s wedding experts make it easy to plan your own wedding! Join for FREE and unlock your personalized wedding checklist.'
            )
        },

        siteGoogle() {
            return this.contentMeta.googlebot || 'index,follow'
        },

        previewImage() {
            return this.contentMeta.image || `${process.env.baseUrl}/main-page.jpg`
        },
    },

    watch: {
        '$auth.loggedIn'(val) {
            if (val) this.$router.replace({ name: 'dashboard' })
        },
    },

    jsonld() {
        return {
            '@context': 'https://schema.org',
            '@type': 'WebPage',
            name: 'Index',
            publisher: 'Loverly',
            url: '/',
        }
    },
}
