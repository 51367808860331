import { mapState } from 'vuex'
export default {
    head() {
        return this.siteMeta
    },

    computed: {
        ...mapState({
            globalTexts: (state) => state.jsonloader.data['global.json'] || null,
        }),

        globalMeta() {
            return this.globalTexts && this.globalTexts.meta ? this.globalTexts.meta : {}
        },

        siteMeta() {
            const metaInfo = {
                meta: [{ hid: 'og:url', property: 'og:url', content: `${process.env.baseUrl}${this.$route.path}` }],
                link: [{ rel: 'canonical', href: `${process.env.baseUrl}${this.$route.path}` }],
            }

            if (this.siteTitle) {
                this.$set(metaInfo, 'title', this.siteTitle)
                this.$set(metaInfo, 'meta', [
                    ...metaInfo.meta,
                    { property: 'title', hid: 'title', content: this.siteTitle },
                    { property: 'og:title', hid: 'og:title', content: this.siteTitle },
                    { property: 'og:site_name', hid: 'og:site_name', content: this.siteTitle },
                ])
            }

            if (this.siteGoogle) {
                this.$set(metaInfo, 'meta', [...metaInfo.meta, { name: 'googlebot', hid: 'googlebot', content: this.siteGoogle }])
            }

            if (this.siteDescription)
                this.$set(metaInfo, 'meta', [
                    ...metaInfo.meta,
                    { name: 'description', hid: 'description', content: this.siteDescription },
                    { property: 'og:description', hid: 'og:description', content: this.siteDescription },
                ])

            if (this.previewImage)
                this.$set(metaInfo, 'meta', [
                    ...metaInfo.meta,
                    { hid: 'image', name: 'image', content: this.previewImage },
                    { hid: 'og:image', property: 'og:image', content: this.previewImage },
                ])

            if (this.keywords) this.$set(metaInfo, 'meta', [...metaInfo.meta, { name: 'keywords', hid: 'keywords', content: this.keywords }])

            return metaInfo
        },

        siteTitle() {
            return this.globalMeta.title || ''
        },

        siteDescription() {
            return this.globalMeta.description || ''
        },

        siteGoogle() {
            return this.globalMeta.googlebot || undefined
        },

        previewImage() {
            return this.globalMeta.image || ''
        },

        keywords() {
            return ''
        },
    },
}
