
import { mapMutations, mapGetters, mapState } from 'vuex'
import hasRegistry from '~/mixins/hasRegistry'
export default {
    mixins: [hasRegistry],

    props: {
        content: {
            type: Object,
            default: () => {},
        },
    },

    computed: {
        ...mapGetters({ isPremiumUser: 'user/isPremiumUser' }),
        ...mapState({
            products: (state) => state.products.list || {},
        }),
    },

    methods: {
        ...mapMutations({
            triggerProductModal: 'products/triggerProductModal',
        }),

        handlePaymentClick() {
            try {
                this.$track('Lead')
                this.$trackEvent('LEAD')
            } catch (e) {}
            if (this.products?.length) {
                this.triggerProductModal(this.getRegistry('i_do_crew.product_id'))
            } else {
                this.$store.dispatch('products/fetchItems').then(() => {
                    this.triggerProductModal(this.getRegistry('i_do_crew.product_id'))
                })
            }
        },
    },
}
